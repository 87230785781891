import { Controller } from '@hotwired/stimulus'
import { useDispatch } from 'stimulus-use'
import googleMapsApiLoader, { google } from '../lib/google-maps-api-loader'

const buildLocationMarker = () => {
  return class LocationMarker extends google.maps.Marker {
    constructor ({ location, map, icons: { highlight, dim }, ...rest }) {
      const position = new google.maps.LatLng(location.lat, location.lng)
      super({ position, map, icon: dim, ...rest })
      this.position = position
      this.icons = { highlight, dim }
      this.location = location
    }

    highlight () {
      this.highlighted = true
      this.setIcon(this.icons.highlight)
      this.setAnimation(google.maps.Animation.BOUNCE)
      setTimeout(() => this.setAnimation(null), 2100)
    }

    dim () {
      this.highlighted = false
      this.setIcon(this.icons.dim)
      this.setAnimation(null)
    }
  }
}

export default class LocationsMapController extends Controller {
  static mapOptions = {
    zoom: 16,
    scrollwheel: false,
    styles: [{
      featureType: 'road.highway',
      elementType: 'labels',
      stylers: [{ hue: '#ffffff' }, { saturation: -100 }, { lightness: 100 }, { visibility: 'off' }]
    },
    {
      featureType: 'landscape.natural',
      elementType: 'all',
      stylers: [{ hue: '#ffffff' }, { saturation: -100 }, { lightness: 100 }, { visibility: 'on' }]
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [{ hue: '#ffe94f' }, { saturation: 100 }, { lightness: 4 }, { visibility: 'on' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ hue: '#ffe94f' }, { saturation: 100 }, { lightness: 4 }, { visibility: 'on' }]
    }, {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ hue: '#333333' }, { saturation: -100 }, { lightness: -74 }, { visibility: 'off' }]
    }]
  }

  static values = {
    apiKey: String, // AIzaSyDwSZVzPd2tySVQ8gV4UrmWVsGCtTHXkWQ
    locations: Array
  }

  static targets = ['map', 'highlightIcon', 'dimIcon']

  async connect () {
    useDispatch(this)
    await googleMapsApiLoader(this.apiKeyValue)
    this.buildMap()
  }

  handleLocationClick ({ detail: { location } }) {
    let highlightedSome = false

    this.locationMarkers.forEach(marker => {
      if (location.id === marker.location.id && !marker.highlighted) {
        marker.highlight()
        highlightedSome = true
        this.map.panTo(marker.position)
        this.map.setZoom(this.map.getZoom() + 2)
      } else {
        marker.dim()
      }
    })

    !highlightedSome && this.resetView()
  }

  buildMap () {
    this.locationMarkers.forEach(marker => {
      marker.addListener('click', () => { this.dispatch('marker:click', { location: marker.location }) })
    })
    this.resetView()
  }

  resetView () {
    google.maps.event.trigger(this.map, 'resize')
    this.map.fitBounds(this.bounds, this.padding)
    this.map.panToBounds(this.bounds)
  }

  get bounds () {
    if (this._bounds) return this._bounds
    this._bounds = new google.maps.LatLngBounds()
    this.locationMarkers.forEach(marker => this._bounds.extend(marker.position))
    return this._bounds
  }

  get padding () {
    return { top: 50, right: 50, left: 50, bottom: 50 }
  }

  get map () {
    if (this._map) return this._map
    this._map = new google.maps.Map(this.mapTarget, this.constructor.mapOptions)
    return this._map
  }

  get locationMarkers () {
    if (this._locationMarkers) return this._locationMarkers
    const LocationMarker = buildLocationMarker()
    this._locationMarkers =
      this.locationsValue.map(location => new LocationMarker({ location, map: this.map, icons: this.icons }))
    return this._locationMarkers
  }

  get icons () {
    return { highlight: this.highlightIconTarget.getAttribute('src'), dim: this.dimIconTarget.getAttribute('src') }
  }
}
