import { Controller } from '@hotwired/stimulus'
import { useDispatch } from 'stimulus-use'

export default class LocationCardController extends Controller {
  static targets = ['highlight']
  static classes = ['active']
  static values = {
    location: Object
  }

  connect () {
    useDispatch(this)
  }

  click (ev) {
    ev.preventDefault()
    this.dispatch('click', { location: this.location })
  }

  handleLocationClick ({ detail: { location } }) {
    if (this.location.id === location.id && !this.active) {
      this.activate()
      return
    }

    this.deactivate()
  }

  activate () {
    this.active = true
    this.highlightTarget.classList.add(this.activeClass)
  }

  deactivate () {
    this.active = false
    this.highlightTarget.classList.remove(this.activeClass)
  }

  get location () {
    return this.locationValue
  }
}
