window.google = window.google || {}
const google = window.google
let loading = false

function googleMapsApiLoader (apiKey) {
  return new Promise(function (resolve, reject) {
    if (window.google.maps) {
      loading = false
      resolve(window.google.maps)
      return
    }

    window.initMap = () => {
      loading = false
      resolve(window.google.maps)
    }

    setTimeout(() => {
      loading = false
      reject(new Error('Timeout'))
    }, 10000)

    if (loading) {
      reject(new Error('in progress'))
      return
    }

    loading = true

    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('defer', 'defer')
    script.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap&v=weekly`)
    script.onerror = reject
    document.body.append(script)
    script.setAttribute('data-turbo-eval', 'false')
  })
}
export { google }
export default googleMapsApiLoader
